<template>
  <q-page class="q-pa-xs-sm q-pa-md-lg q-pa-xl-xl">
    <div v-if="dash" class="row q-col-gutter-sm">
      <div class="col-sm-12 row q-col-gutter-md">
        <m-group-title label="Booking Manager" />
        <div
          v-for="item in pots"
          :key="item.content"
          class="pot col-xs-6 col-sm-4"
        >
          <pot-card
            :type="item.content"
            :label="$tc(`content_type.${item.content.toLowerCase()}`) || item.content"
            :total-count="item.count"
            :errors-count="item.errors_count"
            :amended-count="item.amended_count"
            :booked-today-count="item.booked_today_count"
            :all-link="`/pots/${item.content !== 'other' ? item.content : 'concierge'}`"
            :errors-link="`/pots/${item.content !== 'other' ? item.content : 'concierge'}/errors`"
            :amended-link="`/pots/${item.content !== 'other' ? item.content : 'concierge'}/amended`"
            :booked-today-link="`/pots/${item.content !== 'other' ? item.content : 'concierge'}/booked-today`"
          />
        </div>
        <div class="pot col-xs-6 col-sm-4 col-md-3">
          <router-link
            to="/new/booking"
          >
            <q-card
              class="bg-orange manual-booking-card pot-card row"
            >
              <div class="col-xs-3">
                <q-icon class="manual-booking-card-icon" name="receipt" />
              </div>
              <div class="card-right col-xs-9">
                <div
                  class="type text-right manual-booking-card-text"
                  v-text="'New manual booking request'"
                />
              </div>
            </q-card>
          </router-link>
        </div>
      </div>

      <div class="col-sm-12 row q-col-gutter-sm">
        <m-group-title label="Performance" />

        <div class="col-xs-12 col-sm-6 col-lg-3">
          <q-card class="bg-blue-grey-10">
            <q-card-section class="performance-title">
              Day Revenue
            </q-card-section>
            <q-card-section class="performance-content text-blue-grey-10">
              <div class="row">
                <div class="col-xs-10">
                  <h6>TOTAL</h6>
                  <p class="performance-money text-weight-bold">
                    {{ dash.performance.day_revenue.text }}
                  </p>
                </div>
                <div class="col-xs-2">
                  <q-icon class="performance-chart" name="timeline" />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>

        <div class="col-xs-12 col-sm-6 col-lg-3">
          <q-card class="bg-blue-grey-10">
            <q-card-section class="performance-title">
              Day Margin
            </q-card-section>
            <q-card-section class="performance-content text-blue-grey-10">
              <div class="row">
                <div class="col-xs-10">
                  <h6>TOTAL</h6>
                  <p class="performance-money text-weight-bold">
                    {{ dash.performance.day_margin.text }}
                  </p>
                </div>
                <div class="col-xs-2">
                  <q-icon class="performance-chart" name="timeline" />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>

        <div class="col-xs-12 col-sm-6 col-lg-3">
          <q-card class="bg-cyan-13">
            <q-card-section class="performance-title">
              Month Revenue
            </q-card-section>
            <q-card-section class="performance-content text-cyan-13">
              <div class="row">
                <div class="col-xs-10">
                  <h6>TOTAL</h6>
                  <p class="performance-money text-weight-bold">
                    {{ dash.performance.month_revenue.text }}
                  </p>
                </div>
                <div class="col-xs-2">
                  <q-icon class="performance-chart" name="timeline" />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>

        <div class="col-xs-12 col-sm-6 col-lg-3">
          <q-card class="bg-cyan-13">
            <q-card-section class="performance-title">
              Month Margin
            </q-card-section>
            <q-card-section class="performance-content text-cyan-13">
              <div class="row">
                <div class="col-xs-10">
                  <h6>TOTAL</h6>
                  <p class="performance-money text-weight-bold">
                    {{ dash.performance.month_margin.text }}
                  </p>
                </div>
                <div class="col-xs-2">
                  <q-icon class="performance-chart" name="timeline" />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>

      <div class="col-sm-12 row q-col-gutter-sm">
        <m-group-title label="User Statistics" />
        <div class="col-xs-12 col-sm-6 col-lg-3">
          <q-card class="bg-blue-8">
            <q-card-section>
              <div class="row">
                <div class="col-xs-3 stat-icon-wrapper">
                  <q-icon class="stat-icon" name="person" />
                  <p class="stat-text text-weight-bold">
                    Individuals
                  </p>
                </div>

                <div class="col-xs-9 stat-number">
                  {{ dash.user_statistics.individuals_count }}
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>

        <div class="col-xs-12 col-sm-6 col-lg-3">
          <q-card class="bg-blue-8">
            <q-card-section>
              <div class="row">
                <div class="col-xs-3 stat-icon-wrapper">
                  <q-icon class="stat-icon" name="receipt" />
                  <p class="stat-text text-weight-bold">
                    Bookings
                  </p>
                </div>

                <div class="col-xs-9 stat-number">
                  {{ dash.user_statistics.individuals_booking_count }}
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>

        <div class="col-xs-12 col-sm-6 col-lg-3">
          <q-card class="bg-orange">
            <q-card-section>
              <div class="row">
                <div class="col-xs-3 stat-icon-wrapper">
                  <q-icon class="stat-icon" name="assignment" />
                  <p class="stat-text text-weight-bold">
                    Companies
                  </p>
                </div>

                <div class="col-xs-9 stat-number">
                  {{ dash.user_statistics.company_count }}
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>

        <div class="col-xs-12 col-sm-6 col-lg-3">
          <q-card class="bg-green">
            <q-card-section>
              <div class="row">
                <div class="col-xs-3 stat-icon-wrapper">
                  <q-icon class="stat-icon" name="receipt" />
                  <p class="stat-text text-weight-bold">
                    Bookings
                  </p>
                </div>

                <div class="col-xs-9 stat-number">
                  {{ dash.user_statistics.company_bookings_count }}
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import loading from 'utils/loading'
import potCard from './pot-card'
import { backoffice } from 'api/dashboard'
import { mapGetters } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    potCard
  },
  data () {
    return {
      dash: null
    }
  },
  computed: {
    ...mapGetters({
      consumerToken: 'partner/consumerToken'
    }),
    pots () {
      return this.dash.booking_manager.filter(pot => {
        return pot.content !== 'taxi'
      })
    }
  },
  watch: {
    consumerToken: {
      deep: true,
      handler () {
        this.getBackOffice()
      }
    }
  },
  created () {
    this.getBackOffice()
  },
  methods: {
    getBackOffice () {
      return backoffice()
        .then(res => {
          this.dash = res.data
          loading.stop()
        })
        .catch(() => {
          this.$q.notify({ color: 'negative', icon: 'clear', position: 'bottom', message: 'Error loading dashboard' })
          loading.stop()
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-icon
    padding 5px
    &.rounded
      border-radius 20px !important

.performance-title
  color white
.performance-content
  margin-top 70px
  padding-top 10px
  background white
.performance-money
  font-size 28px
.performance-chart
  font-size 30px
  padding 0
  margin-right 15px
  margin-top 7px

.stat-icon-wrapper
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction column
.stat-icon
  font-size 60px
  color white
  width 100%
.stat-text
  color white
  width 100%
  text-align: center
.stat-number
  text-align right
  line-height 2
  color white
  font-size 45px
.manual-booking-card
  padding 12px
  color white
.new-booking-text
  display: flex;
  align-items: center;

.manual-booking-card-icon
  margin-top -10px
  font-size 48px
  color white

.manual-booking-card-text
  margin-top 26px

@media (min-width: 768px) {
  .performance-chart {
    font-size 3vw
  }
}

@media (min-width: 1199px) {
  .pot {
    width 33%
  }
}
.card-right
  display: flex;
  justify-content: right;
  flex-direction: column;
.number, .type
  flex-basis 100%
  width 100%
  margin-right 15px
.number
  font-size 36px
.type
  font-size 16px
  text-transform capitalize
.pot-card
  color white
  min-height 88px
.pot-card > *
  display: flex;
  align-items: center;
  justify-content: flex-start;
</style>
