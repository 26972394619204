<template>
  <q-card
    class="pot-card"
  >
    <div
      :class="`bg-${color}`"
      class="flex pot-card-header"
    >
      <m-icons-type
        :size="size"
        :inverted="inverted"
        :rounded="rounded"
        :type="type !== 'other' ? type : 'concierge'"
      />
      <div
        class="flex-grow"
        v-text="title"
      />
      <div
        class="flex-block text-right number"
        v-text="totalCount"
      />
    </div>
    <div class="pot-card-actions row q-col-gutter-sm">
      <div class="col-4">
        <q-btn
          class="full-width"
          outline
          :disable="errorsCount < 1"
          :to="errorsLink"
        >
          Errors ({{ errorsCount }})
        </q-btn>
      </div>
      <div class="col-4">
        <q-btn
          class="full-width"
          outline
          :disable="amendedCount < 1"
          :to="amendedLink"
        >
          Amended ({{ amendedCount }})
        </q-btn>
      </div>
      <div class="col-4">
        <q-btn
          class="full-width"
          outline
          :disable="bookedTodayCount < 1"
          :to="bookedTodayLink"
        >
          Completed ({{ bookedTodayCount }})
        </q-btn>
      </div>
      <div class="col-12">
        <q-btn
          class="full-width"
          outline
          :to="allLink"
        >
          View All
        </q-btn>
      </div>
    </div>
  </q-card>
</template>

<script type="text/javascript">
import travelContents from 'mixins/travelContents'
export default {
  name: 'Dashboard',
  mixins: [travelContents],
  props: {
    allLink: {
      type: String,
      default: ''
    },
    errorsLink: {
      type: String,
      default: ''
    },
    amendedLink: {
      type: String,
      default: ''
    },
    bookedTodayLink: {
      type: String,
      default: ''
    },
    label: String,
    totalCount: [String, Number],
    errorsCount: [String, Number],
    amendedCount: [String, Number],
    bookedTodayCount: [String, Number],
    type: String,
    size: {
      type: String,
      default: '24px'
    },
    inverted: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      switch (this.label) {
      case 'other':
        return 'Concierge'
      case 'onwardtravel':
        return 'Onward Travel'
      case 'drt':
        return 'DRT'
      default:
        return this.label.charAt(0).toUpperCase() + this.label.slice(1)
      }
    },
    color () {
      switch (this.type) {
      case 'other':
        return this.getTravelContentColor('concierge')
      case 'ridehailing':
        return this.getTravelContentColor('taxi')
      default:
        return this.getTravelContentColor(this.type) || 'grey'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.card-right
  display: flex;
  justify-content: right;
  flex-direction: column;
.number
  font-size 20px
.type
  font-size 16px
  text-transform capitalize
.pot-card {
  color white
  padding 0

  .pot-card-header {
    border-bottom: 1px solid #eee
    > * {
      padding 5px
    }

    > :first-child {
      margin-left: 5px
    }

    > :last-child {
      padding 5px 10px
    }
  }
}
.pot-card .flex-grow
  flex-grow 1
.pot-card-header
  color: white
.pot-card-actions {
  padding 10px
  color: $primary
}
</style>
